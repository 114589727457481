import * as type from '../type';
import * as user from '../../api/user';

const state = {};

const actions = {
    login({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.login(
                payload.phone,
                payload.sms_code
            ).then(res => {
                if (res.data.res_code == 1) {
                    commit(type.LOGIN, res.data.msg);
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    signUp({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.signUp(
                payload.organ_id,
                payload.province_id,
                payload.city_id,
                payload.area_id,
                payload.unit_id,
                payload.name,
                payload.en_name,
                payload.country,
                payload.sex,
                payload.birthday,
                payload.address,
                payload.guardian_name,
                payload.phone,
                payload.sms_code,
                payload.email,
                payload.group_id,
                payload.opus_name,
                payload.opus_desc,
                payload.recommand_name,
                payload.is_agree
            ).then(res => {
                if (res.data.res_code == 1) {
                    commit(type.LOGIN, res.data.msg);
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    userUpdateYz({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.updateYz(
                payload.yz_phone,
                payload.yz_order_num,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    userUpdateOpus({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.updateOpus(
                payload.opus_img_url,
                payload.opus_width,
                payload.opus_height
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    userGetYz({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.getYz().then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    userGetOpus({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.getOpus().then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    userSetMySignUrl({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            user.setMySignUrl(
                payload.my_sign_url
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
};

const mutations = {
    [type.LOGIN]: (state, payload) => {
        localStorage.setItem("token", payload.token);
    }
};

export default {
    state,
    actions,
    mutations,
}