import axios from 'axios'
import { apiHost } from '../common/env'
// axios 用法：
// https://www.npmjs.com/package/axios
var api = axios.create({
    baseURL: apiHost,
    withCredentials: true,
    timeout: 3600000,
    headers: { "Content-Type": "application/json;charset=UTF-8" },
    transformRequest: [function (data, headers) {
        if (data) {
            data.token = localStorage.getItem('token')
        } else {
            data = {
                token: localStorage.getItem('token')
            }
        }
        return JSON.stringify(data);
    }],
    transformResponse: [function (data) {
        if (JSON.parse(data).res_code < -900) {
            localStorage.removeItem('token');
            window.location.href = "/"
        }
        return JSON.parse(data)
    }]
})

export default api