export const LOGIN = "LOGIN";
export const GET_ORG_LIST = "GET_ORG_LIST"
export const GET_MEIDA_LIST = "GET_MEIDA_LIST"

export const SET_NEWS_BANNER_LIST = 'SET_NEWS_BANNER_LIST';
export const SET_NEWS_LIST = 'SET_NEWS_LIST';
export const SET_NEWS_PAGE_INDEX = 'SET_NEWS_PAGE_INDEX';
export const CLEAR_NEWS_LIST = 'CLEAR_NEWS_LIST';
export const CLEAR_NEWS_BANNER_LIST = 'CLEAR_NEWS_BANNER_LIST';

export const SET_OPUS_LIST = 'SET_OPUS_LIST';
export const SET_OPUS_PAGE_INDEX = 'SET_OPUS_PAGE_INDEX';
export const CLEAR_OPUS_LIST = 'CLEAR_OPUS_LIST';