import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import EnHome from '../views/En/Home/Home.vue'
import FrHome from '../views/Fr/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/home/index',
        name: 'index',
        component: () => import('../views/Index/Index.vue'),
        meta: { belong: 'index' },
      },
      {
        path: '/home/contact',
        name: 'contact',
        component: () => import('../views/Contact/Contact.vue'),
        meta: { belong: 'contact' },
      },
      {
        path: '/home/castle',
        name: 'castle',
        component: () => import('../views/Castle/Castle.vue'),
        meta: { belong: 'castle' },
      },
      {
        path: '/home/group12',
        name: 'group12',
        component: () => import('../views/Group/Group12.vue'),
        meta: { belong: 'group12' },
      },
      {
        path: '/home/group14',
        name: 'group14',
        component: () => import('../views/Group/Group14.vue'),
        meta: { belong: 'group14' },
      },
      {
        path: '/home/group67',
        name: 'group67',
        component: () => import('../views/Group/Group67.vue'),
        meta: { belong: 'group67' },
      },
      {
        path: '/home/group89',
        name: 'group89',
        component: () => import('../views/Group/Group89.vue'),
        meta: { belong: 'group89' },
      },
      {
        path: '/home/group35',
        name: 'group35',
        component: () => import('../views/Group/Group35.vue'),
        meta: { belong: 'group35' },
      },
      {
        path: '/home/guide',
        name: 'guide',
        component: () => import('../views/Guide/Guide.vue'),
        meta: { belong: 'guide' },
      },
      {
        path: '/home/intro',
        name: 'intro',
        component: () => import('../views/Intro/Intro.vue'),
        meta: { belong: 'intro' },
      },
      {
        path: '/home/join',
        name: 'join',
        component: () => import('../views/Join/Join.vue'),
        meta: { belong: 'join' },
      },
      {
        path: '/home/news',
        name: 'news',
        component: () => import('../views/News/News.vue'),
        meta: { belong: 'news' },
      },
      {
        path: '/home/news-detail/:news_id',
        name: 'news-detail',
        component: () => import('../views/News/NewsDetail.vue'),
        meta: { belong: 'news' },
      },
      {
        path: '/home/org',
        name: 'org',
        component: () => import('../views/Org/Org.vue'),
        meta: { belong: 'org' },
      },
      {
        path: '/home/show',
        name: 'show',
        component: () => import('../views/Show/Show.vue'),
        meta: { belong: 'show' },
      },
      {
        path: '/home/register',
        name: 'register',
        component: () => import('../views/Register/Register.vue'),
        meta: { belong: 'register' },
      },
      {
        path: '/home/rule',
        name: 'rule',
        component: () => import('../views/Rule/Rule.vue'),
        meta: { belong: 'rule' },
      },
      {
        path: '/home/join-guide',
        name: 'join-guide',
        component: () => import('../views/JoinGuide/JoinGuide.vue'),
        meta: { belong: 'join-guide' },
      },
      {
        path: '/home/enroll',
        name: 'enroll',
        component: () => import('../views/Enroll/Enroll.vue'),
        meta: { belong: 'enroll' },
      },
      {
        path: '/home/artists',
        name: 'artists',
        component: () => import('../views/Artists/Artists.vue'),
        meta: { belong: 'artists' },
      },
      {
        path: '/home/create',
        name: 'create',
        component: () => import('../views/Create/Create.vue'),
        meta: { belong: 'create' },
      },
    ]
  },
  {
    path: '/en/home',
    name: 'en-home',
    component: EnHome,
    children: [
      {
        path: '/en/home/intro',
        name: 'en-intro',
        component: () => import('../views/En/Intro/Intro.vue'),
        meta: { belong: 'en-intro' },
      },
      {
        path: '/en/home/artists',
        name: 'en-artists',
        component: () => import('../views/En/Artists/Artists.vue'),
        meta: { belong: 'en-artists' },
      },
      {
        path: '/en/home/contact',
        name: 'en-contact',
        component: () => import('../views/En/Contact/Contact.vue'),
        meta: { belong: 'en-contact' },
      },
    ]
  },
  {
    path: '/en/home/introduction',
    name: 'en-introduction',
    component: () => import('../views/En/Introduce/Introduce.vue'),
    meta: { belong: 'en-introduction' },
  },
  {
    path: '/fr/home',
    name: 'fr-home',
    component: FrHome,
    children: [
      {
        path: '/fr/home/intro',
        name: 'fr-intro',
        component: () => import('../views/Fr/Intro/Intro.vue'),
        meta: { belong: 'fr-intro' },
      },
      {
        path: '/fr/home/artists',
        name: 'fr-artists',
        component: () => import('../views/Fr/Artists/Artists.vue'),
        meta: { belong: 'fr-artists' },
      },
      {
        path: '/fr/home/contact',
        name: 'fr-contact',
        component: () => import('../views/Fr/Contact/Contact.vue'),
        meta: { belong: 'fr-contact' },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next();
})

export default router
