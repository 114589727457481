/** 接口地址 */
export const apiHost = "http://iaccapi.wenstech.com/api/";

/** cos地址 */
export const region = "ap-beijing";
export const cosHost = "https://iacc-1257406365.cos.ap-beijing.myqcloud.com/";
export const bucketName = "iacc-1257406365";


/** sus地址 */
export const susHost = apiHost + "file/uploadFile"
export const localHost = "http://iacc-web.wenstech.com/"