import * as type from '../type';
import * as tools from '../../api/tools';
import moment from "moment";

const state = {
  org_list: [],
  media_list: [],
  media_static_list: [],
};

const actions = {
  orgGetList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      tools.orgGetList(
        payload.cate_id
      ).then(res => {
        if (res.data.res_code == 1) {
          commit(type.GET_ORG_LIST, res.data.msg);
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        reject(error);
      })
    })
  },
  mediaGetList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      tools.mediaGetList(
        payload.type
      ).then(res => {
        if (res.data.res_code == 1) {
          commit(type.GET_MEIDA_LIST, { type: payload.type, data: res.data.msg });
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        reject(error);
      })
    })
  },
  positionGetContent({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      tools.getContent(
        payload.position_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(JSON.parse(res.data.msg.content));
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        reject(error);
      })
    })
  },
};

const mutations = {
  [type.GET_ORG_LIST]: (state, payload) => {
    if (payload) {
      let list = [];
      for (let item of payload) {
        list.push(item);
      }

      state.org_list = list;
    }
  },
  [type.GET_MEIDA_LIST]: (state, payload) => {
    if (payload.data) {
      let list = [];
      for (let item of payload.data) {
        list.push(item);
      }
      if (payload.type == 1) {
        state.media_list = list;
      } else {
        state.media_static_list = list;
      }
    }
  },
};

export default {
  state,
  actions,
  mutations
}