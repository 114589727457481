import * as type from '../type'
import * as opus from '../../api/opus'
import moment from 'moment';

const state = {
    opus_list: [],
    opus_page_index: 0,
    opus_page_size: 1000,
    opus_total_count: 0,
};

// const getters = {
//     partOfOpusList: state => {
//         return state.opus_list.splice(83, 2);
//     }
// };

const actions = {
    opusGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            opus.getList(
                payload.page_size,
                payload.page_index
            ).then(res => {
                if (res.data.res_code == 1) {
                    commit(type.SET_OPUS_LIST, res.data.msg);
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    opusGetComment({
        state,
        commit
    }, payload) {
        opus.getComment(
            payload.opus_id
        ).then(res => {
            if (res.data.res_code == 1) {
                resolve(res.data.msg);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            console.error(error);
            reject(error);
        })
    }
};

const mutations = {
    [type.SET_OPUS_LIST]: (state, payload) => {
        if (payload.data) {
            let list = [];
            for (let item of payload.data) {
                let containerWidth = 350;
                let containerHeight = 0;
                let imageWidth = containerWidth - 5 * 2 - 25 * 2;
                let imageHeight = 0;
                let ratio = item.opus_width / imageWidth;
                imageHeight = item.opus_height / ratio;
                containerHeight = imageHeight + 5 * 2 + 25 * 2 + 80;
                item.opus_width = imageWidth;
                item.opus_height = imageHeight;
                item.containerWidth = containerWidth;
                item.containerHeight = containerHeight;
                item.create_time_format = moment(item.d_time).format('YYYY-MM-DD');

                list.push(item);
            }

            state.opus_list = state.opus_list.concat(list);
        }
    },
    [type.SET_OPUS_PAGE_INDEX]: (state, payload) => {
        state.opus_page_index = payload;
    },
    [type.CLEAR_OPUS_LIST]: (state, payload) => {
        state.opus_list = [];
    }
};

export default {
    state,
    // getters,
    actions,
    mutations,
}