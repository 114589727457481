import Vue from 'vue'
import Vuex from 'vuex'

import tools from './modules/tools';
import news from './modules/news';
import opus from './modules/opus';
import user from './modules/user';
import sel from './modules/sel';
import sms from './modules/sms';
import qa from './modules/qa';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tools,
    news,
    opus,
    user,
    sel,
    sms,
    qa,
  }
})
