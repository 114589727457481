import api from './config'

export function getList(
  page_size,
  page_index,
) {
  return api.post('news/getList', {
    page_size,
    page_index,
  })
}

export function getBannerList() {
  return api.post('news/getBannerList', {});
}

export function getDetail(
  news_id,
) {
  return api.post('news/getDetail', {
    news_id,
  })
}
