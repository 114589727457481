import api from './config'

export function getList(
    page_size,
    page_index,
) {
    return api.post('opus/getList', {
        page_size,
        page_index,
    })
}

export function getComment(
    opus_id
) {
    return api.post('opus/getComment', {
        opus_id
    })
}