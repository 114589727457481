import api from './config'

export function orgGetList(
  cate_id
) {
  return api.post('tools_org/getList', {
    cate_id
  })
}

export function mediaGetList(
  type, //1 动态 2 固定
) {
  return api.post('tools_media/getList', {
    type,
  })
}

export function getContent(
  position_id,
) {
  return api.post('position/getContent', {
    position_id,
  })
}