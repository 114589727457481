import api from './config'

export function getOrganList() {
    return api.post('sel/getOrganList', {});
}

export function getProvinceList() {
    return api.post('sel/getProvinceList', {});
}

export function getCityList(
    PROVINCE_CODE
) {
    return api.post('sel/getCityList', {
        PROVINCE_CODE
    });
}

export function getAreaList(
    CITY_CODE
) {
    return api.post('sel/getAreaList', {
        CITY_CODE
    });
}