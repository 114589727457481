import api from './config'

export function login(
    phone,
    sms_code,
) {
    return api.post('user/login', {
        phone,
        sms_code
    })
}

export function signUp(
    organ_id,
    province_id,
    city_id,
    area_id,
    unit_id,
    name,
    en_name,
    country,
    sex,
    birthday,
    address,
    guardian_name,
    phone,
    sms_code,
    email,
    group_id,
    opus_name,
    opus_desc,
    recommand_name,
    is_agree
) {
    return api.post('user/signUp', {
        organ_id,
        province_id,
        city_id,
        area_id,
        unit_id,
        name,
        en_name,
        country,
        sex,
        birthday,
        address,
        guardian_name,
        phone,
        sms_code,
        email,
        group_id,
        opus_name,
        opus_desc,
        recommand_name,
        is_agree
    })
}

export function updateYz(
    yz_phone,
    yz_order_num,
) {
    return api.post('user/updateYz', {
        yz_phone,
        yz_order_num,
    })
}

export function updateOpus(
    opus_img_url,
    opus_width,
    opus_height
) {
    return api.post('user/updateOpus', {
        opus_img_url,
        opus_width,
        opus_height
    })
}

export function getYz() {
    return api.post('user/getYz', {});
}

export function getOpus() {
    return api.post('user/getOpus', {});
}

export function setMySignUrl(my_sign_url) {
    return api.post('user/setMySignUrl', {
        my_sign_url
    })
}