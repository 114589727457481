import * as type from '../type';
import * as news from '../../api/news';
import moment from 'moment';

const state = {
    news_banner_list: [],

    news_list: [],
    news_page_size: 6,
    news_page_index: 0,
    news_total_count: 0,
};

const actions = {
    newsGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            news.getList(
                payload.page_size,
                payload.page_index
            ).then(res => {
                if (res.data.res_code == 1) {
                    commit(type.SET_NEWS_LIST, res.data.msg);
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    newsGetBannerList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            news.getBannerList().then(res => {
                commit(type.SET_NEWS_BANNER_LIST, res.data.msg);
                resolve();
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    newsGetDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            news.getDetail(
                payload.news_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    }
};

const mutations = {
    [type.SET_NEWS_BANNER_LIST]: (state, payload) => {
        state.news_banner_list = payload;
    },
    [type.SET_NEWS_LIST]: (state, payload) => {
        if (payload.data) {
            let list = [];
            for (let item of payload.data) {
                item.create_time_format = moment(item.d_time).format('YYYY-MM-DD');
                list.push(item);
            }
            state.news_list = state.news_list.concat(list);
            state.news_total_count = payload.total_count;
        }
    },
    [type.SET_NEWS_PAGE_INDEX]: (state, payload) => {
        state.news_page_index = payload;
    },
    [type.CLEAR_NEWS_LIST]: (state, payload) => {
        state.news_list = [];
    },
    [type.CLEAR_NEWS_BANNER_LIST]: (state, payload) => {
        state.news_banner_list = [];
    },
};

export default {
    state,
    actions,
    mutations
}