import api from './config'

export function getList(
    page_size,
    page_index
) {
    return api.post('tools_qa/getList', {
        page_size,
        page_index
    })
}