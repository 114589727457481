const IMG_URL = "https://iacc-1257406365.cos.ap-beijing.myqcloud.com/";
const MILK_IMG_URL = 'https://nmd-milk-1257406365.cos.ap-beijing.myqcloud.com/';

export const china = MILK_IMG_URL + 'assets/china.png';
export const arrowDown = MILK_IMG_URL + 'assets/arrow-down.png';
export const logo = IMG_URL + 'assets/logo-nmd.png';
export const triangle = IMG_URL + 'assets/triangle.png';

export const index_arrow_left = IMG_URL + 'assets/index/arrow-left.png';
export const index_arrow_right = IMG_URL + 'assets/index/arrow-right.png';
export const index_banner = IMG_URL + 'assets/index/banner.png/1440';
export const index_video = IMG_URL + 'assets/index/video.mp4';
export const index_video_background = IMG_URL + 'assets/index/video-background.png';
export const index_all = IMG_URL + 'assets/index/all.png';
export const index_arrow_left_dark = IMG_URL + 'assets/index/arrow-left-dark.png';
export const index_arrow_left_light = IMG_URL + 'assets/index/arrow-left-light.png';
export const index_arrow_right_light = IMG_URL + 'assets/index/arrow-right-light.png';
export const index_comment_background = IMG_URL + 'assets/index/comment-background.png';
export const index_comment_avatar_widget = IMG_URL + 'assets/index/comment-avatar-widget.png';

export const intro_banner = IMG_URL + 'assets/intro/banner.png/1440';
export const intro_banner2 = IMG_URL + 'assets_new/intro/banner.png';
export const intro_banner_en = IMG_URL + 'assets/intro/banner_en.jpg/1440';
export const intro_tu1 = IMG_URL + 'assets/intro/tu1.png';
export const intro_tu2 = IMG_URL + 'assets/intro/tu2.png';
export const intro_tu3 = IMG_URL + 'assets/intro/tu3.png';
export const intro_tu4 = IMG_URL + 'assets/intro/tu4.png';
export const intro_tu5 = IMG_URL + 'assets/intro/tu5.png';
export const intro_tu6 = IMG_URL + 'assets/intro/tu6.png';
export const intro_tu7 = IMG_URL + 'assets/intro/tu7.png';
export const intro_tu8 = IMG_URL + 'assets/intro/tu8.png';
export const intro_tu9 = IMG_URL + 'assets/intro/tu9.png';
export const intro_tu10 = IMG_URL + 'assets/intro/tu10.png';
export const intro_tu11 = IMG_URL + 'assets/intro/tu11.png';
export const intro_tu12 = IMG_URL + 'assets/intro/tu12.png';
export const intro_tu13 = IMG_URL + 'assets/intro/tu13.png';
export const intro_tu14 = IMG_URL + 'assets/intro/tu14.png';
export const intro_triangle1 = IMG_URL + 'assets/intro/triangle1.png';
export const intro_triangle2 = IMG_URL + 'assets/intro/triangle2.png';
export const intro_triangle3 = IMG_URL + 'assets/intro/triangle3.png';
export const intro_triangle4 = IMG_URL + 'assets/intro/triangle4.png';
export const intro_qrcode = IMG_URL + 'assets/intro/qrcode.png';
export const introduction_foldi = IMG_URL + 'assets/intro/introduction_foldi.jpg';
export const introduction_ronel = IMG_URL + 'assets/intro/introduction_ronel.jpg';

export const award_liste = IMG_URL + 'assets/award/liste.png';

export const guide_image1 = IMG_URL + 'assets/guide/image1.png';
export const guide_image2 = IMG_URL + 'assets/guide/image2.png';

export const org_image1 = IMG_URL + 'assets/org/image1.png';
export const org_image2 = IMG_URL + 'assets/org/image2.png';

export const news_mask = IMG_URL + 'assets/news/mask.png/1440';
export const news_vague = IMG_URL + 'assets/news/vague.png';
export const news_btn_more_normal = IMG_URL + 'assets/news/btn_more_normal.png';
export const news_btn_more_hover = IMG_URL + 'assets/news/btn_more_hover.png';

export const castle_banner = IMG_URL + 'assets/castle/banner.png/1440';
export const castle_video = IMG_URL + 'assets/castle/video.mp4';

export const show_title = IMG_URL + 'assets/show/title.png';

export const group_artist12 = IMG_URL + 'assets/group/artist12.png';
export const group_artist12p1 = IMG_URL + 'assets/group/artist12p1.jpg';
export const group_artist12p2 = IMG_URL + 'assets/group/artist12p2.jpg';
export const group_artist35 = IMG_URL + 'assets/group/artist35.png';
export const group_artist35p1 = IMG_URL + 'assets/group/artist35p1.jpg';
export const group_artist35p2 = IMG_URL + 'assets/group/artist35p2.jpg';
export const group_artist14 = IMG_URL + 'assets/group/artist14.png';
export const group_artist14p1 = IMG_URL + 'assets/group/artist14p1.jpg';
export const group_artist14p2 = IMG_URL + 'assets/group/artist14p2.jpg';
export const group_artist67 = IMG_URL + 'assets/group/artist67.png';
export const group_artist67p1 = IMG_URL + 'assets/group/artist67p1.jpg';
export const group_artist67p2 = IMG_URL + 'assets/group/artist67p2.jpg';
export const group_artist89 = IMG_URL + 'assets/group/artist89.png';
export const group_artist89p1 = IMG_URL + 'assets/group/artist89p1.jpg';
export const group_artist89p2 = IMG_URL + 'assets/group/artist89p2.jpg';
export const group_banner12 = IMG_URL + 'assets/group/banner12.png/1440';
export const group_banner14 = IMG_URL + 'assets/group/banner14.png/1440';
export const group_banner35 = IMG_URL + 'assets/group/banner35.png/1440';
export const group_banner67 = IMG_URL + 'assets/group/banner67.png/1440';
export const group_banner89 = IMG_URL + 'assets/group/banner89.png/1440';
export const group_triangle_left = IMG_URL + 'assets/group/triangle_left.png';
export const group_triangle_right = IMG_URL + 'assets/group/triangle_right.png';
export const group_arrow = IMG_URL + 'assets/group/arrow.png';
export const group_video_artist35 = IMG_URL + 'assets/group/malgorzata paszko.mp4';
export const group_video_artist67 = IMG_URL + 'assets/group/christophe ronel.mp4';
export const group_video_artist89 = IMG_URL + 'assets/group/emmanuelle renard.mp4';
export const group_video_artist12 = IMG_URL + 'assets/group/sophie sainrapt.mp4';
export const group_video_artist14 = IMG_URL + 'assets/group/augusto foldi.mp4';


export const index_banner2 = IMG_URL + 'assets_new/index/banner.png/1440';
export const index_arrow_yellow = IMG_URL + 'assets_new/index/arrow_yellow.png';
export const index_arrow_yellow_sml = IMG_URL + 'assets_new/index/arrow_yellow_sml.png';
export const index_arrow_orange_sml = IMG_URL + 'assets_new/index/arrow_orange_sml.png';
export const index_sche_step1 = IMG_URL + 'assets_new/index/sche_step_1.png';
export const index_sche_step2 = IMG_URL + 'assets_new/index/sche_step_2.png';
export const index_sche_step3 = IMG_URL + 'assets_new/index/sche_step_3.png';
export const index_sche_step4 = IMG_URL + 'assets_new/index/sche_step_4.png';
export const index_sche_step1_new = IMG_URL + 'assets_new/index/sche_step_1.jpg';
export const index_sche_step2_new = IMG_URL + 'assets_new/index/sche_step_2.jpg';
export const index_sche_step3_new = IMG_URL + 'assets_new/index/sche_step_3.jpg';
export const index_word_news = IMG_URL + 'assets_new/index/word_news.png';
export const index_word_online_gallery = IMG_URL + 'assets_new/index/word_online_gallery.png';
export const index_page_prev = IMG_URL + 'assets_new/index/page_prev.png';
export const index_page_next = IMG_URL + 'assets_new/index/page_next.png';
export const index_view_all = IMG_URL + 'assets_new/index/view_all.png';
export const index_view_all2 = IMG_URL + 'assets_new/index/view_all_2.png';
export const index_arrow_orange = IMG_URL + 'assets_new/index/arrow_orange.png';

export const footer_qr_code = IMG_URL + 'assets_new/index/qr_code.png';

export const contact_bali = IMG_URL + 'assets/contact/bali.png';
export const contact_beijing = IMG_URL + 'assets/contact/beijing.png';
export const contact_bali_en = IMG_URL + 'assets/contact/paris_en.png';
export const contact_beijing_en = IMG_URL + 'assets/contact/beijing_en.png';
export const contact_paris_fr = IMG_URL + 'assets/contact/paris_fr.png';
export const contact_pekin_fr = IMG_URL + 'assets/contact/pekin_fr.png';

export const register_info_detail = IMG_URL + 'mini-icon/info-detail.png'
export const register_agree_checked = IMG_URL + 'mini-icon/agree-checked.png';
export const register_agree_normal = IMG_URL + 'mini-icon/agree-normal.png';

export const artists_banner = IMG_URL + 'assets_new/artists/banner.png';
export const artists_huxian = IMG_URL + 'assets_new/artists/huxian.png';
export const artists_augusto_foldi_video = IMG_URL + 'assets_new/artists/augusto foldi.mp4';
export const artists_christophe_ronel_video = IMG_URL + 'assets_new/artists/christophe ronel.mp4';
export const artists_emmanuelle_renard_video = IMG_URL + 'assets_new/artists/emmanuelle renard.mp4';
export const artists_malgorzata_paszko_video = IMG_URL + 'assets_new/artists/malgorzata paszko.mp4';
export const artists_sophie_sainrapt_video = IMG_URL + 'assets_new/artists/sophie sainrapt.mp4';
export const artists_augusto_foldi_image = IMG_URL + 'assets_new/artists/augusto foldi.jpg';
export const artists_christophe_ronel_image = IMG_URL + 'assets_new/artists/christophe ronel.jpg';
export const artists_emmanuelle_renard_image = IMG_URL + 'assets_new/artists/emmanuelle renard.jpg';
export const artists_malgorzata_paszko_image = IMG_URL + 'assets_new/artists/malgorzata paszko.jpg';
export const artists_sophie_sainrapt_image = IMG_URL + 'assets_new/artists/sophie sainrapt.jpg';

export const join_guide_title = IMG_URL + 'mini-icon/title.png';

export const create_row_center = IMG_URL + 'assets/create/1.jpg';
export const create_row_end = IMG_URL + 'assets/create/2.jpg';
export const create_col_top = IMG_URL + 'assets/create/3.jpg';
export const create_row_top = IMG_URL + 'assets/create/4.jpg';
export const create_xmbs_row = IMG_URL + 'assets/create/5.jpg';
export const create_xmbs_row_center = IMG_URL + 'assets/create/6.jpg';

export const artists_en_paszko1 = IMG_URL + 'assets/artists_en/paszko1.jpg';
export const artists_en_paszko2 = IMG_URL + 'assets/artists_en/paszko2.jpg';
export const artists_en_ronel1 = IMG_URL + 'assets/artists_en/ronel1.jpg';
export const artists_en_ronel2 = IMG_URL + 'assets/artists_en/ronel2.jpg';
export const artists_en_renard1 = IMG_URL + 'assets/artists_en/renard1.jpg';
export const artists_en_renard2 = IMG_URL + 'assets/artists_en/renard2.jpg';
export const artists_en_sainrapt1 = IMG_URL + 'assets/artists_en/sainrapt1.jpg';
export const artists_en_sainrapt2 = IMG_URL + 'assets/artists_en/sainrapt2.jpg';
export const artists_en_foldi1 = IMG_URL + 'assets/artists_en/foldi1.jpg';
export const artists_en_foldi2 = IMG_URL + 'assets/artists_en/foldi2.jpg';
export const artists_en_foldi = IMG_URL + 'assets/artists_en/Augusto foldi.jpg';
export const artists_en_ronel = IMG_URL + 'assets/artists_en/Christophe Ronel.jpg';
export const artists_en_renard = IMG_URL + 'assets/artists_en/Emmanuelle Renard.jpg';
export const artists_en_paszko = IMG_URL + 'assets/artists_en/Malgorzata Paszko.jpg';
export const artists_en_sainrapt = IMG_URL + 'assets/artists_en/Sophie Sainrapt.jpg';