<template>
  <div class="p-root">
    <header class="header-root-container">
      <a-space>
        <router-link class="nav nav-parent" to="/fr/home/intro">Introduce</router-link>
        <router-link class="nav nav-parent" to="/fr/home/contact">Contact Us</router-link>
        <router-link class="nav nav-parent" to="/fr/home/artists">Artists</router-link>
        <a-popover placement="bottom">
          <div class="header-language">
            <!-- <img :src="icons.china" /> -->
            <div style="margin-left: 5px">French</div>
            <img :src="icons.arrowDown" />
          </div>
          <a-space direction="vertical" slot="content">
            <div class="nav nav-child" @click="goChinese">中文</div>
            <div class="nav nav-child" @click="goEnglish">English</div>
          </a-space>
        </a-popover>
      </a-space>
    </header>
    <main>
      <transition name="my-fade" mode="out-in">
        <router-view />
      </transition>
    </main>
    <footer class="footer-root-container">
      <div class="footer-menu-container" :style="{
          backgroundColor: backgroundColor,
          color: fontColor,
        }">
        <div class="column" style="margin-right: 20px">
          <!-- <img :src="icons.footer_qr_code" style="width: 100px" />
          <span style="
              font-weight: bold;
              font-size: 14px;
              text-align: center;
              margin-top: 3px;
              width: 100px;
            ">扫描关注公众号联系我们</span>

          <span style="margin-top: 60px; font-size: 14px">合作可加微信：bayan88</span>
          <span style="font-size: 14px">写明“国际艺术联创巡展”</span> -->
        </div>
        <div class="row" style="
            align-items: flex-start;
            justify-content: space-around;
            flex: 1;
          ">
          <div class="column" style="min-width: 100px">
            <router-link class="column-item nav" :style="{ color: fontColor }" to="/fr/home/intro">Introduce
            </router-link>
          </div>
          <div class="column" style="min-width: 100px">
            <router-link class="column-item nav" :style="{ color: fontColor }" to="/fr/home/contact">Contact Us
            </router-link>
          </div>
          <div class="column" style="min-width: 100px">
            <router-link class="column-item nav" :style="{ color: fontColor }" to="/fr/home/artists">Artists
            </router-link>
          </div>
        </div>
      </div>
      <div class="footer-record-container">
        www.lacocrea.com All rights reserved. 京ICP备05006633号-12
      </div>
    </footer>
  </div>
</template>

<script>
import * as eventHelper from "@/utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
      backgroundColor: "#e9bf74",
      fontColor: "#282828",
    };
  },
  mounted() {
    eventHelper.addEventListener(
      "footer-change-background",
      this.changeBackground
    );
  },
  methods: {
    changeBackground(params) {
      this.backgroundColor = params.backgroundColor;
      this.fontColor = params.fontColor;
    },
    goChinese() {
      this.$router.push({
        name: "index",
      });
    },
    goEnglish() {
      this.$router.push({
        name: "en-intro",
      });
    },
  },
};
</script>

<style>
/* header 部分 */
.header-root-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  height: 80px;
  z-index: 1000;
}
.header-france-site {
  font-family: MalayalamMN;
  font-size: 13px;
  color: #282828;
  margin-right: 54px;
  cursor: pointer;
}
.header-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  color: #282828;
  margin-right: 15px;
  cursor: pointer;
}
.header-shop {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: PingFangSC-Semibold;
  font-size: 13px;
  color: #282828;
  margin-right: 45px;
  cursor: pointer;
}

main {
  margin-top: 80px;
  min-height: calc(100vh - 500px);
}

/* footer 部分 */
.footer-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.footer-menu-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  padding-top: 55px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
}
.footer-record-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  padding: 25px 0px;
}

/* 布局部分 */
.nav {
  cursor: pointer;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  letter-spacing: -0.62px;
}
.nav-default {
  cursor: default;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  letter-spacing: -0.62px;
}
.nav-parent {
  margin-right: 40px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #282828;
  letter-spacing: -0.62px;
}
.nav-child {
  width: 180px;
  height: 50px;
  color: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-child:hover {
  background: #f72e02;
  color: white;
}
.nav-child-small {
  width: 180px;
  height: 50px;
  display: flex;
  color: #282828;
  justify-content: center;
  align-items: center;
}
.nav-child-large {
  width: 220px;
  height: 50px;
  display: flex;
  color: #282828;
  justify-content: center;
  align-items: center;
}
.nav-child-small:hover {
  background: #f72e02;
  color: white;
}
.nav-child-large:hover {
  background: #f72e02;
  color: white;
}
.column-title {
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #282828;
}
.column-item {
  margin-bottom: 25px;
  z-index: 2;
  font-size: 18px;
}
.footer-desc {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  letter-spacing: -0.62px;
}

a.nav:hover {
  color: #f72e02;
}
a.nav-child-large:hover {
  color: white;
}
a.nav-child-small:hover {
  color: white;
}

.footer-title {
  color: #ff3000;
  font-weight: bold;
}
</style>
