let eventMap = new Map();

export function addEventListener(key, event) {
  if (!eventMap.has(key)) {
    eventMap.set(key, []);
  }

  let events = eventMap.get(key);
  events.push(event);
  eventMap.set(key, events);
}

export function removeEventListener(key, event) {
  if (!eventMap.has(key)) {
    return;
  }

  let events = eventMap.get(key);
  for (let index in events) {
    if (events[index] === event) {
      events.splice(index, 1);
      return;
    }
  }
}

export function triggerEvent(key, params) {
  if (!eventMap.has(key)) {
    return;
  }
  let events = eventMap.get(key);
  for (let event of events) {
    event(params);
  }
}